/**
 * Initializes a notification based on a cookie.
 *
 * @param {string} selector - The CSS selector for the notification element.
 * @param {string} cookieName - The name of the cookie to check for showing the notification.
 * @param {number} delay - The delay in milliseconds before showing the notification.
 */
function initializeNotification(selector, cookieName, delay) {
	const notificationElement = document.querySelector(selector);

	if (shouldShowNotification(cookieName)) {
		showNotificationAfterDelay(notificationElement, delay);
		setNotificationCookieOnClose(notificationElement, cookieName);
	}
}

/**
 * Determines whether to show the notification based on the cookie's presence.
 *
 * @param {string} cookieName - The name of the cookie to check.
 * @return {boolean} - True if the notification should be shown, false otherwise.
 */
function shouldShowNotification(cookieName) {
	return localStorage.getItem(cookieName) === null;
}

/**
 * Shows the notification element after a specified delay.
 *
 * @param {Element} element - The DOM element for the notification.
 * @param {number} delay - The delay in milliseconds before showing the notification.
 */
function showNotificationAfterDelay(element, delay) {
	setTimeout(() => {
		element.showModal();
	}, delay);
}

/**
 * Sets a cookie when the notification is closed.
 *
 * @param {Element} element - The DOM element for the notification.
 * @param {string} cookieName - The name of the cookie to set.
 */
function setNotificationCookieOnClose(element, cookieName) {
	element.addEventListener('close', () => {
		localStorage.setItem(cookieName, 'true');
	});
}

export { initializeNotification };
